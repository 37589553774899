
import Vue from 'vue';

export default Vue.extend({
  name: 'PictureComponent',
  props: {
    link: {
      type: Object,
    },
    linkTarget: {
      type: String,
    },
    alt: {
      type: String,
    },
    title: {
      type: String,
    },
    noLazyLoading: {
      type: Boolean,
    },
    imageOptimization: {
      type: Boolean,
    },
    image: {
      type: Object,
    },
  },
});
